<template>
  <section class="w-full py-10 px-3 relative border-b border-zinc-200">
    <span
      style="background-image: url(/images/bg/bg_hero_top.svg)"
      class="w-full h-32 absolute top-0 left-0 bg-no-repeat bg-left bg-contain z-0 md:h-40 xl:h-44 2xl:h-48"
    ></span>
    <div class="container mx-auto">
      <div class="flex flex-col items-center justify-between gap-10 py-32 relative z-10">
        <header class="text-center">
          <h1 class="font-semibold text-2xl mb-5 md:text-4xl">Conversemos</h1>
          <p class="text-base md:w-1/2 md:mx-auto">
            En <strong>Vanda</strong>, nos enorgullece nuestro trabajo y te invitamos a conocer
            de cerca cómo trabajamos y los emocionantes proyectos que podemos abordar juntos.
          </p>
        </header>
        <button
          @click="setModalOpen(true)"
          type="button"
          class="block w-40 mx-auto py-2 bg-zinc-700 text-gray-50 rounded-md hover:bg-zinc-950 transition-all md:w-48"
        >
          Agenda una reunión
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import { useModalCalendarContext } from '@/contexts/ModalCalendarContext';

const { setModalOpen } = useModalCalendarContext();
</script>
