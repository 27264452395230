import axios from 'axios'

const API_URL = 'https://strapi-vanda-api-60088087481.southamerica-west1.run.app/api'

export const getBlogs = async () => {
  try {
    const response = await axios.get(`${API_URL}/blogs`)
    console.log('API response:', response)
    return response
  } catch (error) {
    console.error('Error fetching blogs:', error)
    throw error
  }
}

export const getBlog = async (documentId) => {
  try {
    const response = await axios.get(`${API_URL}/blogs/${documentId}?populate=images`)
    console.log('API response:', response)
    return response
  } catch (error) {
    console.error(`Error fetching blog with documentId ${documentId}:`, error)
    throw error
  }
}

export const getPublishedBlogs = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/blogs?filters[published][$eq]=true&populate=images`,
    )
    console.log('API response:', response)
    return response
  } catch (error) {
    console.error('Error fetching published blogs:', error)
    throw error
  }
}
