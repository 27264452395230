<template>
  <div class="min-h-screen flex flex-col mt-20 bg-gray-50">
    <main class="flex-grow container mx-auto py-10 px-4 sm:px-6 lg:px-8">
      <article v-if="blog" class="bg-white shadow-lg rounded-lg overflow-hidden transition-all duration-300">
        <header class="p-8 bg-green-lightest text-primary rounded-t-lg relative">
          <div class="relative z-10">
            <FwbHeading tag="h1" class="text-4xl sm:text-5xl font-extrabold mb-4 text-green-dark">
              {{ blog.title }}
            </FwbHeading>
            <FwbHeading tag="h2" class="text-2xl sm:text-3xl font-semibold mb-6 opacity-90">
              {{ blog.subtitle }}
            </FwbHeading>
            <time :datetime="blog.publishedAt" class="text-sm opacity-80">
              {{ new Date(blog.publishedAt).toLocaleDateString() }}
            </time>
          </div>
        </header>

        <img v-if="blog.image" :src="getImageUrl(blog)" :alt="blog.title"
          class="w-full h-72 sm:h-96 object-cover rounded-xl shadow-md mb-8 transition-transform duration-500 ease-in-out" />

        <!-- Contenido del blog -->
        <div class="p-6 sm:p-8 bg-gray-100 rounded-b-lg">
          <div class="prose prose-lg max-w-none text-gray-800 leading-relaxed">
            <StrapiBlocks :content="contentHTML" :blocks="userBlocks" :modifiers="userModifiers" />
          </div>
          <!-- Botón para abrir el modal -->
          <div class="flex justify-center mt-8">
            <button @click="openModal"
              class="px-6 py-3 bg-green-500 text-white text-lg rounded-md hover:bg-green-600 transition-all">
              Conversemos
            </button>
          </div>
        </div>
      </article>

      <FwbP v-if="error" class="text-red-600 bg-red-100 border border-red-400 rounded p-4 mt-6">
        {{ error }}
      </FwbP>
    </main>

    <!-- Agregar el modal -->
    <CaptureDataModal :show="showModal" @close="closeModal" />
  </div>
</template>

<script setup>
import { ref, onMounted, h } from 'vue';
import { useRoute } from 'vue-router';
import { getBlog } from '@/services/BlogService';
import { FwbHeading, FwbP } from 'flowbite-vue';
import { StrapiBlocks } from 'vue-strapi-blocks-renderer';
import CaptureDataModal from '@/components/ui/CaptureDataModal.vue';

const route = useRoute();
const blog = ref(null);
const error = ref(null);
const contentHTML = ref([]);
const showModal = ref(false);

const getImageUrl = (blog) => {
  if (blog.image && blog.image.url) {
    // Si la URL ya comienza con https:// (URL de Cloudinary), úsala directamente
    if (blog.image.url.startsWith('http')) {
      return blog.image.url;
    }
    // De lo contrario, usa el comportamiento anterior
    return `https://strapi-vanda-api-60088087481.southamerica-west1.run.app${blog.image.url}`;
  }
  // Retorna una imagen por defecto si no hay imagen
  return '/path/to/default/image.jpg';
};

const closeModal = () => {
  showModal.value = false;
  localStorage.setItem('modalShown', 'true');
};

const openModal = () => {
  showModal.value = true;
};

const userBlocks = {
  paragraph: (props) =>
    h(
      'p',
      {
        class:
          'custom-paragraph mb-4 text-lg text-gray-800 leading-relaxed transition-all duration-500 ease-in-out',
      },
      props.children
    ),
  heading: (props) => {
    const level = props.level || 1;
    return h(
      `h${level}`,
      {
        class: `custom-heading text-${level === 1 ? '4xl text-green-dark' : level === 2 ? '2xl text-green' : level === 3 ? 'xl text-secondary' : level === 4 ? 'lg text-gray-warm' : level === 5 ? 'base text-green-light' : 'sm text-gray-warm'} font-semibold mt-6 mb-4 tracking-tight transform transition-all duration-300 ease-in-out`,
      },
      props.children
    );
  },
  list: (props) =>
    h(
      props.format === 'ordered' ? 'ol' : 'ul',
      { class: 'custom-list ml-6 mb-4 text-gray-800' },
      props.children
    ),
  quote: (props) =>
    h(
      'blockquote',
      { class: 'custom-quote border-l-4 border-gray-300 pl-6 italic my-6 text-gray-600' },
      props.children
    ),
  code: (props) =>
    h(
      'pre',
      {
        class:
          'custom-code bg-gray-200 p-4 rounded-md shadow-lg transition-all duration-300 ease-in-out',
      },
      props.plainText
    ),
  image: (props) =>
    h('img', {
      src: props.image.url,
      alt: props.image.alternativeText,
      class:
        'custom-image rounded-xl shadow-md mb-8 transition-transform duration-500 ease-in-out',
    }),
  link: (props) =>
    h(
      'a',
      {
        href: props.url,
        class:
          'custom-link text-green-500 hover:text-green-700 transition-colors duration-200 ease-in-out',
      },
      props.children
    ),
  hr: () =>
    h('hr', {
      class: 'custom-hr my-8 border-t border-gray-300 transition-all duration-300 ease-in-out',
    }),
};

const userModifiers = {
  bold: (props) =>
    h('strong', { class: 'custom-bold font-semibold text-primary' }, props.children),
  italic: (props) => h('em', { class: 'custom-italic italic text-secondary' }, props.children),
  underline: (props) => h('u', { class: 'custom-underline underline' }, props.children),
  strikethrough: (props) =>
    h('s', { class: 'custom-strikethrough line-through text-gray-500' }, props.children),
  code: (props) =>
    h(
      'code',
      { class: 'custom-code-inline bg-gray-100 p-1 rounded text-sm font-mono' },
      props.children
    ),
};

onMounted(async () => {
  try {
    const response = await getBlog(route.params.id);
    if (response.data && response.data.data) {
      const blogData = response.data.data;
      blog.value = {
        id: blogData.id,
        documentId: blogData.documentId,
        title: blogData.title,
        subtitle: blogData.subtitle,
        contentHTML: blogData.contentHTML,
        publishedAt: blogData.publishedAt,
        image: blogData.images && blogData.images.length > 0 ? blogData.images[0] : null,
      };
      contentHTML.value = blog.value.contentHTML;
    } else {
      throw new Error('Blog data not found in response');
    }
  } catch (err) {
    error.value = 'Failed to fetch blog. Please try again later.';
    console.error(err);
  }

  if (!localStorage.getItem('modalShown')) {
    setTimeout(() => {
      showModal.value = true;
    }, 10000);
  }
});
</script>

<style scoped>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Mejora de las imágenes dentro del contenido */
.prose img {
  @apply rounded-xl shadow-md mb-8 transition-transform duration-500 ease-in-out;
}

.prose a {
  @apply text-green-500 hover:text-green-700 transition-colors duration-200 ease-in-out;
}

.prose h1 {
  @apply text-4xl font-bold mb-4 text-green-dark leading-tight;
}

.prose h2 {
  @apply text-2xl font-semibold mt-8 mb-6 text-gray-900;
}

.prose h3 {
  @apply text-xl font-semibold mt-6 mb-3 text-secondary;
}

.prose h4 {
  @apply text-lg font-semibold mt-6 mb-3 text-gray-700;
}

.prose h5 {
  @apply text-base font-semibold mt-6 mb-3 text-green-light;
}

.prose h6 {
  @apply text-sm font-semibold mt-6 mb-3 text-gray-500;
}

.prose p {
  @apply mb-4 text-lg text-gray-800 leading-relaxed bg-gray-50 p-4 rounded-lg shadow-sm transition-all duration-500 ease-in-out;
}

.prose ul,
.prose ol {
  @apply ml-6 mb-4;
}

.prose li {
  @apply mb-4;
}

.prose blockquote {
  @apply border-l-4 border-gray-300 pl-6 italic my-6 text-gray-600;
}

.prose hr {
  @apply my-8 border-t border-gray-300 transition-all duration-300 ease-in-out;
}

/* Mejora de los botones y las redes sociales */
button {
  @apply font-semibold text-sm transition-all ease-in-out;
}

button:hover {
  transform: scale(1.05);
}

button:focus {
  @apply outline-none ring-4 ring-green-light;
}

/* Estilo del artículo */
article {
  background-color: #ffffff;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
}

article:hover {
  box-shadow: none;
}

header {
  background-color: #E3F2C5;
  border-bottom: 2px solid #e5e7eb;
  border-radius: 8px;
  position: relative;
}

h1,
h2 {
  color: #1A171B;
  position: relative;
  z-index: 10;
}

time {
  font-size: 0.875rem;
  color: #1A171B;
  position: relative;
  z-index: 10;
}

/* Estilos para los bloques */
.custom-paragraph {
  @apply mb-4 text-lg text-gray-800 leading-relaxed bg-gray-50 p-4 rounded-lg shadow-sm;
}

.custom-heading {
  @apply font-semibold mt-8 mb-6 text-gray-900 tracking-tight;
}

.custom-list {
  @apply ml-6 mb-4 list-inside text-gray-800;
}

.custom-quote {
  @apply border-l-4 border-gray-300 pl-6 italic my-6 text-gray-600;
}

.custom-code {
  @apply bg-gray-200 p-4 rounded-md shadow-lg transition-all duration-300 ease-in-out;
}

.custom-image {
  @apply rounded-xl shadow-md mb-8 transition-transform duration-500 ease-in-out;
}

.custom-link {
  @apply text-green-500 hover:text-green-700 transition-colors duration-200 ease-in-out;
}

.custom-hr {
  @apply my-8 border-t border-gray-300 transition-all duration-300 ease-in-out;
}

.custom-bold {
  @apply font-semibold text-primary;
}

.custom-italic {
  @apply italic text-secondary;
}

.custom-underline {
  @apply underline;
}

.custom-strikethrough {
  @apply line-through text-gray-500;
}

.custom-code-inline {
  @apply bg-gray-100 p-1 rounded text-sm font-mono;
}
</style>
