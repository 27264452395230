<template>
  <TransitionRoot :show="show" as="template">
    <Dialog as="div" class="relative z-50" @click.self="closeModal">
      <TransitionChild as="template" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100"
        leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
        <div class="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" @click="closeModal" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-xl bg-white text-left shadow-2xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div class="bg-white px-6 pt-6 pb-6 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle as="h3" class="text-2xl font-semibold text-gray-900 mb-4">
                      Ayúdanos a mejorar, por favor
                    </DialogTitle>
                    <p class="text-gray-600 mb-4 text-sm">Nos encantaría saber un poco más sobre ti para ofrecerte una
                      experiencia personalizada. ¡Gracias por tu ayuda!</p>
                    <div class="space-y-4">
                      <input v-model="odoo_DTO.name" type="text" placeholder="Tu nombre"
                        class="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm" />
                      <input v-model="odoo_DTO.email" type="email" placeholder="Tu correo electrónico"
                        class="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm" />
                      <input v-model="odoo_DTO.company" type="text" placeholder="Tu empresa"
                        class="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm" />
                      <input v-model="odoo_DTO.jobTitle" type="text" placeholder="Tu cargo"
                        class="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm" />
                      <input v-model="odoo_DTO.phone" type="tel" placeholder="Tu teléfono"
                        class="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm" />
                      <textarea v-model="odoo_DTO.demoDescription"
                        placeholder="¿Qué esperas de una demostración personalizada?"
                        class="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                        rows="4"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button @click="submitData" type="button"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-lg px-4 py-2 bg-indigo-600 text-base font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:w-auto sm:text-sm">
                  ¡Enviar y ayudar!
                </button>
                <button @click="closeModal" type="button"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                  Cancelar
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionRoot, TransitionChild } from '@headlessui/vue';

const props = defineProps(['show']);
const emit = defineEmits(['close']);

const odoo_DTO = reactive({
  name: "",
  email: "",
  company: "",
  jobTitle: "",
  phone: "",
  demoDescription: "",
  country: "",
  city: "",

});


onMounted(() => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // Usa un servicio como Nominatim para obtener el país y la ciudad
        fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.coords.latitude}&lon=${position.coords.longitude}`)
          .then(response => response.json())
          .then(data => {
            odoo_DTO.country.value = data.address.country || 'Chile'; // Valor por defecto: Chile
            odoo_DTO.city.value = data.address.city || data.address.town || data.address.village || 'Santiago'; // Valor por defecto: Santiago
          })
          .catch(error => {
            console.error('Error al obtener la geolocalización:', error);
            odoo_DTO.country.value = 'Chile'; // Valor por defecto: Chile
            odoo_DTO.city.value = 'Santiago'; // Valor por defecto: Santiago
          });
      },
      (error) => {
        console.error('Error al obtener la geolocalización:', error);
        odoo_DTO.country.value = 'Chile'; // Valor por defecto: Chile
        odoo_DTO.city.value = 'Santiago'; // Valor por defecto: Santiago
      }
    );
  } else {
    console.error('La geolocalización no está soportada por este navegador.');
    odoo_DTO.country.value = 'Chile'; // Valor por defecto: Chile
    odoo_DTO.city.value = 'Santiago'; // Valor por defecto: Santiago
  }
});

function closeModal() {
  emit('close');
}

async function submitData() {

  try {
    const response = await fetch('https://smartgo-landing-odoo-api-2xqxogiglq-tl.a.run.app/api/odoo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(odoo_DTO),
    });

    if (response.ok) {
      const data = await response.json();
      console.log(data.message);
      if (data.lead_id) {
        console.log('Lead ID:', data.lead_id);
      }
      closeModal();
    } else {
      const errorData = await response.json();
      console.error('Error al enviar datos a Odoo:', errorData.detail);
    }
  } catch (error) {
    console.error('Error:', error);
  }
}
</script>

<style scoped>
/* Aquí puedes agregar estilos adicionales si es necesario */
</style>
