import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/pages/IndexMain.vue'
import SoftwareFactory from '@/pages/services/software-factory.vue'
import ProcessAutomation from '@/pages/services/process-automation.vue'
import BigData from '@/pages/services/big-data.vue'
import CloudServices from '@/pages/services/cloud-services.vue'
import BlogList from '@/pages/BlogList.vue'
import BlogDetail from '@/pages/BlogDetail.vue'
import OdooLandingPage from '@/pages/OdooLandingPage.vue'
import CVSSView from '@/pages/CVSSView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/services/software-factory',
    name: 'SoftwareFactory',
    component: SoftwareFactory,
  },
  {
    path: '/services/process-automation',
    name: 'ProcessAutomation',
    component: ProcessAutomation,
  },
  {
    path: '/services/big-data',
    name: 'BigData',
    component: BigData,
  },
  {
    path: '/services/cloud-services',
    name: 'CloudServices',
    component: CloudServices,
  },
  { path: '/blogs', name: 'BlogList', component: BlogList },
  { path: '/blogs/:id', name: 'BlogDetail', component: BlogDetail },
  {
    path: '/odoo-landing-page',
    name: 'OdooLandingPage',
    component: OdooLandingPage,
  },
  {
    path: '/cvss',
    name: 'cvss',
    component: CVSSView,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
