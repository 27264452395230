<template>
  <section class="odoointegration-section">
    <div class="odoointegration-content">
      <div class="odoointegration-carousel">
        <Carousel :autoplay="2000" :navigationEnabled="false" :paginationEnabled="false" :wrapAround="true">
          <Slide v-for="(picture, index) in pictures" :key="index">
            <img :src="picture.src" :alt="picture.alt" class="carousel-image" />
          </Slide>
        </Carousel>
      </div>
      <div class="odoointegration-text">
        <h1 class="odoointegration-title">{{ title }}</h1>
        <p class="odoointegration-subtitle">{{ subtitle }}</p>
        <p class="odoointegration-description">{{ description }}</p>
        <button @click="goToIntegrationPage" class="odoointegration-cta-button">
          {{ buttonText }}
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import { useModalCalendarContext } from '@/contexts/ModalCalendarContext';

const { setModalOpen } = useModalCalendarContext();

const props = defineProps({
  title: {
    type: String,
    default: 'Transforma tu Empresa con Odoo y la Experiencia de Vanda',
  },
  subtitle: {
    type: String,
    default:
      'Especialistas en Odoo, ayudamos a empresas a optimizar y automatizar procesos para incrementar productividad.',
  },
  description: {
    type: String,
    default:
      'Implementamos Odoo de forma personalizada para optimizar ventas, inventarios, proyectos y más. ¡Haz crecer tu negocio con la solución más flexible y escalable!',
  },
  buttonText: {
    type: String,
    default: 'Mas información',
  },
  buttonLink: {
    type: String,
    default: '/odoo-landing-page',
  },
});

const goToIntegrationPage = () => {
  window.location.href = props.buttonLink;
};

const pictures = [
  {
    src: 'https://odoocdn.com/openerp_website/static/src/img/assets/png/odoo_logo_inverted.png',
    alt: 'Image 1',
  },
  {
    src: 'https://odoocdn.com/openerp_website/static/src/img/apps/crm/quotation.webp',
    alt: 'Image 2',
  },
  {
    src: 'https://portcities.net/web/image/22726-52b30562/CRM%20pipeline.png',
    alt: 'Image 3',
  },
];
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.odoointegration-section {
  @apply relative flex justify-center items-center p-5 bg-gradient-to-l from-odoo-primary via-odoo-secondary to-odoo-gray font-sans;
  overflow: hidden;
  perspective: 1px;
  transform-style: preserve-3d;
}

.odoointegration-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('https://www.example.com/video.mp4') no-repeat center center/cover;
  opacity: 0.5;
  z-index: -1;
  transform: translateZ(-1px) scale(2);
}

.odoointegration-content {
  @apply flex flex-col md:flex-row items-center justify-between w-full max-w-6xl;
  animation: fadeIn 1s ease-in-out;
}

.odoointegration-carousel,
.odoointegration-text {
  @apply flex-1 w-full md:w-1/2;
}

.odoointegration-carousel {
  @apply mb-8 md:mb-0 md:mr-20 md:-ml-60;
}

.carousel-image {
  @apply w-full h-auto object-cover rounded-lg;
  animation: slideIn 1s ease-in-out;
}

.odoointegration-text {
  @apply text-center md:text-left text-white md:ml-24;
}

.odoointegration-title {
  @apply text-3xl md:text-5xl font-bold leading-tight text-white mb-4;
  animation: slideInLeft 1s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.odoointegration-subtitle {
  @apply text-xl md:text-3xl mt-3 font-medium leading-snug text-white mb-4;
  animation: slideInLeft 1.2s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.odoointegration-description {
  @apply text-base md:text-xl mt-6 font-medium leading-snug text-white opacity-90 mb-6;
  animation: slideInLeft 1.4s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.odoointegration-cta-button {
  @apply mt-8 px-8 py-4 text-base md:text-xl bg-gradient-to-r from-sec-100 to-sec-200 text-white border-none rounded-md cursor-pointer transition-all duration-200 shadow-lg;
}

.odoointegration-cta-button:hover {
  @apply bg-gradient-to-r from-sec-200 to-sec-100 shadow-xl;
  color: white !important;
  /* Mantener el color del texto blanco */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
