<template>
  <div class="cvss-calculator">
    <h1 class="text-2xl font-bold mb-4">Calculadora CVSS v3.0</h1>
    <div class="mb-4">
      <label for="vector" class="block font-semibold">Ingresa el vector CVSS:</label>
      <input
        id="vector"
        v-model="vector"
        type="text"
        placeholder="Ejemplo: AV:N/AC:L/PR:N/UI:N/S:U/C:H/I:H/A:H"
        class="border border-gray-300 rounded p-2 w-full"
      />
    </div>
    <button @click="calculateCVSS" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
      Calcular
    </button>
    <div v-if="result" class="mt-4">
      <p><strong>Vector:</strong> {{ result.vector }}</p>
      <p><strong>Puntuación Base:</strong> {{ result.base_score }}</p>
      <p><strong>Severidad:</strong> {{ result.severity }}</p>
    </div>
  </div>
</template>

<script>
import cvss from 'cvss'
export default {
  data() {
    return {
      vector: "",
      result: null
    };
  },
  methods: {
    calculateCVSS() {
      try {
        const score = cvss.getScore(this.vector);
        this.result = {
          vector: this.vector,
          base_score: score,
          severity: score >= 9 ? 'Critical' : score >= 7 ? 'High' : score >= 4 ? 'Medium' : 'Low'
        };
      } catch (error) {
        alert("Error: " + error.message);
      }
    }
  }
};
</script>

<style>
.cvss-calculator {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}
</style>
