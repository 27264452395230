import { ref, onMounted, onUnmounted, nextTick } from 'vue';

export const useIntersectionObserver = (target, options = { threshold: 0.1 }) => {
  const isIntersecting = ref(false);

  const observer = new IntersectionObserver(([entry]) => {
    console.log('IntersectionObserver triggered:', entry);
    isIntersecting.value = entry.isIntersecting;
    console.log('Element is in view:', entry.isIntersecting);
  }, options);

  onMounted(async () => {
    await nextTick(); // Espera a que el DOM esté completamente renderizado
    if (target.value) {
      console.log('Observing element:', target.value); // Verifica si el observador está observando el elemento
      observer.observe(target.value);
    }
  });

  onUnmounted(() => {
    if (target.value) {
      observer.unobserve(target.value);
    }
  });

  return isIntersecting;
};
