<template>
  <section id="products" class="w-full scroll-mt-20 border-t border-b bg-zinc-100">
    <div class="container mx-auto py-10 px-3">
      <header class="flex items-center justify-center mb-10">
        <h2 class="font-semibold text-2xl text-center w-full">Nuestros productos</h2>
      </header>

      <div class="flex items-center justify-center gap-2 mb-5">
        <button v-for="(slide, index) in slidesID" :key="slide" type="button" @click="handleClick(index)"
          class="w-10 h-8 grid place-content-center">
          <span :class="`w-10 h-1 ${current === index ? 'bg-sec-100' : 'bg-slate-300'}`" />
        </button>
      </div>

      <Swiper ref="sliderRef" :spaceBetween="50" :slidesPerView="1" @slideChange="onSlideChange" @swiper="onSwiper">
        <SwiperSlide>
          <div class="product-slide">
            <div class="content text-center">
              <div class="logo-container">
                <img src="/images/logo/logo_smartgo.svg" alt="SmartGo Logo" class="logo" />
              </div>
              <h3 class="title">SmartGO</h3>
              <h4 class="subtitle">Creado por Vanda para ser tu aliado estratégico</h4>
              <p class="description">
                <span class="highlight">SmartGO</span> es una plataforma de software que se compone de un Middleware el
                cual permite integrar rápidamente aplicaciones internas y externas.
              </p>
              <p class="description">
                Contiene funcionalidades para <span class="highlight">implementar Workflows</span> (modelando todos tus
                procesos de negocio) permitiendo además el Desarrollo de Pluggins por medio de su componente SDK.
              </p>
              <p class="description">
                Con el <span class="highlight">componente Bridge</span> puedes gestionar Eventos, Colas de Mensajería y
                RPAs.
              </p>
              <a href="http://smartgo.cl" target="_blank" class="link">Conoce más</a>
            </div>
            <div class="image-container mt-5">
              <img src="/images/products/smartgo.png" alt="SmartGo Image" class="product-image" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="product-slide">
            <div class="content text-center">
              <div class="logo-container">
                <img src="/images/logo/logo_plannix.svg" alt="Plannix Logo" class="logo" />
              </div>
              <h3 class="title">Plannix</h3>
              <h4 class="subtitle">S&PO: Forecast y MRP</h4>
              <ul class="description-list">
                <li class="description-item">
                  <span class="highlight">Optimización de inventario y reducción de costos</span> al determinar los
                  niveles de inventario óptimo.
                </li>
                <li class="description-item">
                  Equilibrio entre la <span class="highlight">oferta y la demanda</span>, a través de los datos
                  históricos de ventas.
                </li>
                <li class="description-item">
                  Mejora la <span class="highlight">eficiencia operativa</span> con una programación de producción más
                  eficiente.
                </li>
                <li class="description-item">
                  Mejora del <span class="highlight">servicio al cliente</span>, al cumplir con la demanda.
                </li>
              </ul>
              <a href="http://plannix.cl" target="_blank" class="link">Conoce más</a>
            </div>
            <div class="image-container mt-5">
              <img src="/images/products/plannix.png" alt="Plannix Image" class="product-image" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="product-slide">
            <div class="content text-center">
              <div class="logo-container">
                <img src="/images/logo/logo_legalify.png" alt="Legalify Logo" class="logo logo-legalify" />
              </div>
              <h3 class="title">Legalify</h3>
              <h4 class="subtitle">Gestión de casos judiciales para personas naturales y jurídicas</h4>
              <p class="description">
                Legalify es el <span class="highlight">software de gestión legal</span> para gestionar todo el ciclo de
                vida de las causas.
              </p>
              <p class="description">
                Legalify <span class="highlight">rolea las causas de forma automática, administra los documentos
                  asociados, gestiona el calendario con las citas y actividades relevantes, mantiene toda la información
                  de los clientes y sus causas en un solo lugar</span>, sin importar dónde te encuentres.
              </p>
              <p class="description">
                <span class="highlight">Hace todo más rápido y con menos riesgo de error</span> para concentrarse en las
                tareas que realmente son las más importantes para tu negocio legal.
              </p>
              <a href="http://legalify.cl" target="_blank" class="link">Conoce más</a>
            </div>
            <div class="image-container mt-5">
              <img src="/images/products/legalify.png" alt="Legalify Image" class="product-image" />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

const current = ref(0);
const sliderRef = ref(null);
let swiperInstance = null;
const slidesID = ['smartgo', 'plannix', 'legalify'];

const handleClick = (target) => {
  if (swiperInstance) {
    swiperInstance.slideTo(target);
    current.value = target;
  } else {
    console.error('Swiper no está disponible o inicializado');
  }
};

onMounted(() => {
  setTimeout(() => {
  }, 500);
});

const onSwiper = (swiper) => {
  sliderRef.value = swiper;
  swiperInstance = swiper;
};

const onSlideChange = (swiper) => {
  current.value = swiper.activeIndex;
};
</script>

<style scoped>
.product-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.content {
  max-width: 80%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  max-width: 80%;
  display: flex;
  justify-content: center;
}

.product-image {
  width: 100%;
  height: auto;
}

.logo-container {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.logo {
  width: 100px;
  height: auto;
}

.logo-legalify {
  width: 120px;
  height: 80px;
  margin-top: -10px;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: 1.25rem;
  font-weight: normal;
  margin-bottom: 1rem;
}

.description {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.description-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 1rem 0;
}

.description-item {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.highlight {
  font-weight: bold;
  color: #6956A8;
}

.link {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #6956A8;
  color: white;
  text-decoration: none;
  border-radius: 0.25rem;
  transition: background-color 0.3s;
}

.link:hover {
  background-color: #8E80BE;
}

/* Responsivo para dispositivos móviles */
@media (max-width: 768px) {
  .product-slide {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .content,
  .image-container {
    max-width: 100%;
  }

  .title {
    font-size: 1.25rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .logo {
    width: 80px;
  }

  .logo-legalify {
    width: 100px;
    height: 60px;
  }

  .image-container {
    margin-top: 20px;
  }
}

/* Responsivo para dispositivos de escritorio */
@media (min-width: 769px) {
  .product-slide {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }

  .content,
  .image-container {
    max-width: 45%;
  }

  .title {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 1.25rem;
  }

  .description {
    font-size: 1rem;
  }

  .logo {
    width: 100px;
  }

  .logo-legalify {
    width: 120px;
    height: 80px;
  }
}
</style>
