<template>
  <button @click="scrollToTop" :class="[
    'fixed right-4 w-8 h-8 text-zinc-50 rounded-full flex items-center justify-center bg-prime-200 transition-all duration-300',
    shouldShow ? 'opacity-1 bottom-4' : 'opacity-0 -bottom-4',
  ]">
    <ArrowUpIcon class="w-5 h-5" />
  </button>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { ArrowUpIcon } from '@heroicons/vue/24/solid';

const shouldShow = ref(false);

const handleScroll = () => {
  const headerBox = document.querySelector('#header')?.getBoundingClientRect();
  if (headerBox && window.scrollY >= headerBox.height) {
    shouldShow.value = true;
  } else {
    shouldShow.value = false;
  }
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style scoped>
/* Add your styles here if necessary */
</style>
