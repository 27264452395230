<template>
  <div>
    <section class="w-full min-h-fit border-b border-b-zinc-200 mb-10 flex items-center pt-20">
      <div
        class="container mx-auto py-20 px-3 flex flex-col items-center justify-center gap-4 lg:flex-row lg:w-3/5 lg:gap-10 lg:items-start"
      >
        <img
          src="/images/servicios/process-automation.png"
          alt="Process Automation (Automatización de procesos de negocio)"
          class="w-16 aspect-square lg:w-24"
        />
        <div
          class="text-zinc-800 text-center lg:text-left flex flex-col items-center lg:items-start"
        >
          <router-link
            to="/"
            class="text-sm leading-6 text-c2-200 flex items-center hover:text-zinc-500"
          >
            <ChevronLeftIcon class="w-4 h-4" /> Volver
          </router-link>
          <FwbHeading tag="h1" class="text-2xl mb-5 font-medium uppercase">
            Process Automation (Automatización de procesos de negocio)
          </FwbHeading>
          <FwbP class="text-sm text-zinc-500">
            Nuestro equipo de trabajo experto en el despliegue de optimización y automatización
            de procesos tiene la capacidad de analizar, desarrollar y alinear procesos de
            negocios existentes con los objetivos de tu organización. Ofrecemos una consultoría
            y desarrollo altamente orientados al negocio para aumentar la rentabilidad,
            disminuir el time to market y optimizar el tiempo de ejecución de procesos
            internos. Nuestro software SmartGO es altamente integrable entre plataformas ERP y
            CRM para conseguir una mayor automatización, flexibilidad, seguridad y
            optimización, lo que lo convierte en una solución global para tu organización.
          </FwbP>
        </div>
      </div>
    </section>

    <section class="container mx-auto py-10 px-3 lg:py-20 lg:w-3/4">
      <header class="mb-5 xl:mb-10 xl:w-3/4 xl:mx-auto xl:text-center">
        <FwbHeading tag="h1" class="text-xl text-center font-xl"
          >¿Qué significa Automatización de Procesos?</FwbHeading
        >
      </header>
      <div class="flex justify-between gap-5">
        <QuestionMarkCircleIcon class="w-8 h-8 text-c2-200 shrink-0" />
        <FwbP class="text-sm">
          La automatización de procesos de negocio (BPA) es un servicio integral que utiliza
          tecnología y software especializado para optimizar y gestionar eficientemente las
          tareas y flujos de trabajo dentro de una empresa. El objetivo principal de la BPA es
          eliminar tareas manuales repetitivas, minimizar los errores humanos y agilizar la
          ejecución de procesos empresariales complejos, lo que conduce a una mayor eficiencia
          operativa y un mejor rendimiento general.
        </FwbP>
      </div>
    </section>

    <section class="container mx-auto py-10 px-3 lg:py-20">
      <header class="mb-5 xl:mb-10 xl:w-3/4 xl:mx-auto xl:text-center">
        <FwbHeading tag="h1" class="text-xl text-center font-xl">
          Beneficios de la Automatización de Procesos de negocio
        </FwbHeading>
      </header>
      <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 xl:gap-8">
        <div
          ref="detailBox1"
          :class="{
            'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
            'opacity-100 translate-y-0': onScreen1,
            'opacity-0 translate-y-10': !onScreen1,
            'lg:flex-row': true,
          }"
        >
          <CheckCircleIcon class="w-6 h-6 text-c2-200 shrink-0 lg:w-8 lg:h-8" />
          <div class="text-center lg:text-left">
            <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl"
              >Aumento de la eficiencia</FwbHeading
            >
            <FwbP class="text-xs lg:text-sm">
              Permite que las empresas realicen tareas y procesos más rápidamente, lo que
              reduce los tiempos de respuesta y acelera la entrega de productos o servicios a
              los clientes. Esto se traduce en una mayor productividad y una ventaja
              competitiva en el mercado.
            </FwbP>
          </div>
        </div>

        <div
          ref="detailBox2"
          :class="{
            'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
            'opacity-100 translate-y-0': onScreen2,
            'opacity-0 translate-y-10': !onScreen2,
            'lg:flex-row': true,
          }"
        >
          <CheckCircleIcon class="w-6 h-6 text-c2-200 shrink-0 lg:w-8 lg:h-8" />
          <div class="text-center lg:text-left">
            <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl"
              >Reducción de costos</FwbHeading
            >
            <FwbP class="text-xs lg:text-sm">
              Al automatizar tareas que antes requerían mano de obra intensiva, las empresas
              pueden reducir sus costos operativos, ya que se eliminan los gastos asociados con
              el empleo de personal adicional.
            </FwbP>
          </div>
        </div>

        <div
          ref="detailBox3"
          :class="{
            'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
            'opacity-100 translate-y-0': onScreen3,
            'opacity-0 translate-y-10': !onScreen3,
            'lg:flex-row': true,
          }"
        >
          <CheckCircleIcon class="w-6 h-6 text-c2-200 shrink-0 lg:w-8 lg:h-8" />
          <div class="text-center lg:text-left">
            <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl"
              >Mejora de la precisión</FwbHeading
            >
            <FwbP class="text-xs lg:text-sm">
              La automatización disminuye la probabilidad de errores humanos, lo que conduce a
              una mayor precisión en los procesos empresariales. Esto es particularmente
              beneficioso en sectores donde la precisión es crítica, como la contabilidad y la
              gestión de inventarios.
            </FwbP>
          </div>
        </div>

        <div
          ref="detailBox4"
          :class="{
            'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
            'opacity-100 translate-y-0': onScreen4,
            'opacity-0 translate-y-10': !onScreen4,
            'lg:flex-row': true,
          }"
        >
          <CheckCircleIcon class="w-6 h-6 text-c2-200 shrink-0 lg:w-8 lg:h-8" />
          <div class="text-center lg:text-left">
            <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl">
              Mayor cumplimiento normativo
            </FwbHeading>
            <FwbP class="text-xs lg:text-sm">
              La BPA puede ayudar a las empresas a cumplir con regulaciones y estándares
              gubernamentales o de la industria al garantizar que los procesos se ejecuten de
              acuerdo con las políticas y procedimientos establecidos.
            </FwbP>
          </div>
        </div>

        <div
          ref="detailBox5"
          :class="{
            'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
            'opacity-100 translate-y-0': onScreen5,
            'opacity-0 translate-y-10': !onScreen5,
            'lg:flex-row': true,
          }"
        >
          <CheckCircleIcon class="w-6 h-6 text-c2-200 shrink-0 lg:w-8 lg:h-8" />
          <div class="text-center lg:text-left">
            <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl">
              Mayor visibilidad y control
            </FwbHeading>
            <FwbP class="text-xs lg:text-sm">
              Los sistemas de automatización de procesos suelen ofrecer paneles de control y
              análisis de datos en tiempo real, lo que brinda a las empresas una visión más
              profunda de sus operaciones y les permite tomar decisiones informadas de manera
              más rápida.
            </FwbP>
          </div>
        </div>

        <div
          ref="detailBox6"
          :class="{
            'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
            'opacity-100 translate-y-0': onScreen6,
            'opacity-0 translate-y-10': !onScreen6,
            'lg:flex-row': true,
          }"
        >
          <CheckCircleIcon class="w-6 h-6 text-c2-200 shrink-0 lg:w-8 lg:h-8" />
          <div class="text-center lg:text-left">
            <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl">
              Flexibilidad y escalabilidad
            </FwbHeading>
            <FwbP class="text-xs lg:text-sm">
              Los sistemas de BPA son adaptables y escalables, lo que significa que pueden
              ajustarse a medida que las necesidades de la empresa evolucionan con el tiempo.
              Esto permite una mayor agilidad y capacidad de respuesta a los cambios del
              mercado.
            </FwbP>
          </div>
        </div>

        <div
          ref="detailBox7"
          :class="{
            'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
            'opacity-100 translate-y-0': onScreen7,
            'opacity-0 translate-y-10': !onScreen7,
            'lg:flex-row': true,
          }"
        >
          <CheckCircleIcon class="w-6 h-6 text-c2-200 shrink-0 lg:w-8 lg:h-8" />
          <div class="text-center lg:text-left">
            <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl">
              Mejora en la satisfacción del cliente
            </FwbHeading>
            <FwbP class="text-xs lg:text-sm">
              Al acelerar los procesos y reducir los errores, la BPA puede mejorar la
              experiencia del cliente al proporcionar un servicio más rápido y confiable.
            </FwbP>
          </div>
        </div>
      </div>
    </section>

    <section class="container mx-auto py-10 px-3 lg:py-20 lg:w-3/4">
      <FwbP class="text-sm text-center lg:text-base">
        En resumen, la automatización de procesos de negocio es una herramienta poderosa para
        mejorar la eficiencia, reducir costos y aumentar la precisión en una empresa, lo que a
        su vez conduce a una mayor competitividad y satisfacción del cliente.
      </FwbP>
    </section>

    <SectionCTA />
  </div>
</template>

<script setup>
import { FwbHeading, FwbP } from 'flowbite-vue';
import { ref, onMounted, onUnmounted } from 'vue';
import {
  ChevronLeftIcon,
  QuestionMarkCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/24/outline';
import SectionCTA from '@/components/sections/SectionCTA.vue';

const detailBox1 = ref(null);
const detailBox2 = ref(null);
const detailBox3 = ref(null);
const detailBox4 = ref(null);
const detailBox5 = ref(null);
const detailBox6 = ref(null);
const detailBox7 = ref(null);
const onScreen1 = ref(false);
const onScreen2 = ref(false);
const onScreen3 = ref(false);
const onScreen4 = ref(false);
const onScreen5 = ref(false);
const onScreen6 = ref(false);
const onScreen7 = ref(false);

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.target === detailBox1.value) {
        onScreen1.value = entry.isIntersecting;
      } else if (entry.target === detailBox2.value) {
        onScreen2.value = entry.isIntersecting;
      } else if (entry.target === detailBox3.value) {
        onScreen3.value = entry.isIntersecting;
      } else if (entry.target === detailBox4.value) {
        onScreen4.value = entry.isIntersecting;
      } else if (entry.target === detailBox5.value) {
        onScreen5.value = entry.isIntersecting;
      } else if (entry.target === detailBox6.value) {
        onScreen6.value = entry.isIntersecting;
      } else if (entry.target === detailBox7.value) {
        onScreen7.value = entry.isIntersecting;
      }
    });
  },
  { threshold: 0.5 }
);

onMounted(() => {
  if (detailBox1.value) observer.observe(detailBox1.value);
  if (detailBox2.value) observer.observe(detailBox2.value);
  if (detailBox3.value) observer.observe(detailBox3.value);
  if (detailBox4.value) observer.observe(detailBox4.value);
  if (detailBox5.value) observer.observe(detailBox5.value);
  if (detailBox6.value) observer.observe(detailBox6.value);
  if (detailBox7.value) observer.observe(detailBox7.value);
});

onUnmounted(() => {
  if (detailBox1.value) observer.unobserve(detailBox1.value);
  if (detailBox2.value) observer.unobserve(detailBox2.value);
  if (detailBox3.value) observer.unobserve(detailBox3.value);
  if (detailBox4.value) observer.unobserve(detailBox4.value);
  if (detailBox5.value) observer.unobserve(detailBox5.value);
  if (detailBox6.value) observer.unobserve(detailBox6.value);
  if (detailBox7.value) observer.unobserve(detailBox7.value);
});
</script>
