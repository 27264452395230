<script setup>
import AppHeader from './components/header/AppHeader.vue';
import AppFooter from './components/footer/footer.vue';
import FloatingButton from './components/ui/FloatingButton.vue';
import ModalCalendar from './components/ui/ModalCalendar.vue';
import { provideModalCalendarContext } from '@/contexts/ModalCalendarContext';
import { provideModalCalendarSelectionContext } from '@/contexts/ModalCalendarSelectionContext';
import { ref, onMounted } from 'vue';

provideModalCalendarContext();
provideModalCalendarSelectionContext();
</script>

<template>
  <div id="home" class="min-h-screen flex flex-col">
    <AppHeader />
    <main class="flex-grow flex flex-col justify-center">
      <router-view />
    </main>
    <AppFooter />
    <FloatingButton />
    <ModalCalendar />
  </div>
</template>

<style scoped>
#home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
