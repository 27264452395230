<template>
  <div id="blogs" class="min-h-screen flex flex-col">
    <main class="flex-grow mt-20">
      <div class="container mx-auto py-10 px-3">
        <div class="flex justify-center mb-10">
          <fwb-heading tag="h2" class="text-center">Nuestros Blogs</fwb-heading>
        </div>
        <div v-if="blogs.length" class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-16 mt-10">
          <!-- Primer Blog -->
          <div v-for="(blog, index) in limitedBlogs" :key="blog.id"
            :class="index === 1 ? 'flex justify-center' : 'flex justify-start'">
            <router-link :to="{ name: 'BlogDetail', params: { id: blog.documentId } }" class="w-full max-w-md">
              <fwb-card :img-alt="blog.title" :img-src="getImageUrl(blog)" variant="image"
                class="h-full transition-all duration-300 hover:scale-105 shadow-xl rounded-xl">
                <div class="p-6 h-full flex flex-col justify-between">
                  <div>
                    <!-- Solo mostramos el título y el subtítulo -->
                    <h5 class="mb-4 text-2xl font-semibold text-gray-900 hover:text-prime-100 transition-colors">
                      {{ blog.title }}
                    </h5>
                    <h6 class="mb-4 text-lg font-medium text-gray-600">
                      {{ blog.subtitle }}
                    </h6>
                  </div>
                </div>
              </fwb-card>
            </router-link>
          </div>
        </div>

        <p v-if="error" class="text-red-600 bg-red-100 border border-red-400 rounded p-4 mt-6">
          {{ error }}
        </p>

        <!-- Botón para ver más Blogs -->
        <div class="flex justify-center mt-12">
          <router-link to="/blogs"
            class="px-6 py-3 bg-zinc-800 text-white rounded-md hover:bg-zinc-900 transition-all flex items-center gap-2">
            <span>Ver más Blogs</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" stroke="currentColor"
              viewBox="0 0 24 24" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M13 7l5 5-5 5M6 12h12"></path>
            </svg>
          </router-link>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { getPublishedBlogs } from '@/services/BlogService';
import { FwbCard, FwbHeading } from 'flowbite-vue';

const blogs = ref([]); // Arreglo para almacenar los blogs
const error = ref(null); // Variable para manejar errores

const getImageUrl = (blog) => {
  if (blog.image && blog.image.url) {
    // Si la URL ya comienza con https:// (URL de Cloudinary), úsala directamente
    if (blog.image.url.startsWith('http')) {
      return blog.image.url;
    }
    // De lo contrario, usa el comportamiento anterior
    return `https://strapi-vanda-api-60088087481.southamerica-west1.run.app${blog.image.url}`;
  }
  // Retorna una imagen por defecto si no hay imagen
  return '/path/to/default/image.jpg';
};

const limitedBlogs = computed(() => {
  return blogs.value.slice(0, 3); // Limitar a 3 blogs para visualización
});

onMounted(async () => {
  try {
    const response = await getPublishedBlogs(); // Llamada al servicio que filtra los blogs publicados
    console.log('Blog data structure:', response.data.data[0]);

    // Verificamos que la respuesta sea válida
    if (response.data && Array.isArray(response.data.data)) {
      blogs.value = response.data.data.map((blog) => ({
        id: blog.id,
        documentId: blog.documentId,
        title: blog.title,
        subtitle: blog.subtitle,
        content: blog.content,
        publishedAt: blog.publishedAt,
        image: blog.images && blog.images.length > 0 ? blog.images[0] : null,
      }));
    } else {
      error.value = 'Unexpected data structure from API';
    }
  } catch (err) {
    error.value = 'Failed to fetch blogs. Please try again later.';
    console.error('Error fetching blogs:', err);
  }
});
</script>

<style scoped>
/* Ajustes generales */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 4rem;
  /* Aumento del espacio entre las tarjetas */
}

@media (min-width: 640px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Estilo del hover y de las tarjetas */
.fwb-card {
  transition: all 0.3s ease;
  border-radius: 1rem;
}

.fwb-card:hover {
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
}

h5 {
  font-size: 1.25rem;
  /* Aumenta un poco el tamaño del título */
  line-height: 1.5rem;
}

h6 {
  font-size: 1rem;
  /* Aumenta ligeramente el subtítulo */
  color: #4b5563;
  /* Color más suave para el subtítulo */
}
</style>
