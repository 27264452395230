<template>
  <div
    ref="elementRef"
    :class="{
      'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in-out duration-500': true,
      'opacity-100 translate-y-0': onScreen,
      'opacity-0 translate-y-10': !onScreen,
      'lg:flex-row': true,
    }"
  >
    <CheckCircleIcon class="w-6 h-6 text-c1-200 shrink-0 lg:w-8 lg:h-8" />
    <div class="text-center lg:text-left">
      <h3 class="text-md font-semibold mb-2 text-c1 lg:text-xl">{{ title }}</h3>
      <p class="text-xs lg:text-sm">{{ content }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { useIntersectionObserver } from '@/composables/useIntersectionObserver';

const props = defineProps({
  title: String,
  content: String,
});

const elementRef = ref(null);

onMounted(() => {
  console.log('ElementRef inside mounted:', elementRef.value); // Verifica si se resuelve correctamente
});

const onScreen = useIntersectionObserver(elementRef, { threshold: 0.1 });
</script>
