<template>
  <div
    ref="scrollerRef"
    class="group max-w-full data-[animated=true]:overflow-hidden scroller-mask"
  >
    <div
      class="flex gap-4 py-4 flex-wrap group-data-[animated=true]:flex-nowrap group-data-[animated=true]:animate-scroll group-data-[animated=true]:min-w-max"
    >
      <div
        v-for="client in CLIENTS"
        :key="client.key"
        class="h-5 w-auto shrink-0 md:h-8 xl:h-12"
      >
        <img
          :src="`/images/clients/${client.filename}`"
          :alt="client.name"
          class="grayscale block h-full w-auto"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const CLIENTS = [
  { key: 'aon', name: 'AON', filename: 'aon.png' },
  { key: 'aramark', name: 'Aramark', filename: 'aramark.png' },
  { key: 'astara', name: 'Astara', filename: 'astara.png' },
  { key: 'chilquinta', name: 'Chilquinta Distribución', filename: 'chilquinta.png' },
  { key: 'electrolux', name: 'Electrolux', filename: 'electrolux.png' },
  { key: 'enjoy', name: 'Enjoy', filename: 'enjoy.png' },
  { key: 'entel', name: 'Entel', filename: 'entel.png' },
  { key: 'harting', name: 'Härting', filename: 'harting.png' },
  { key: 'hmc', name: 'HMC Capital', filename: 'hmc.png' },
  { key: 'pedidosya', name: 'PedidosYa', filename: 'pedidosya.png' },
  { key: 'skberge', name: 'SKBergé', filename: 'skberge.png' },
  { key: 'tecnofarma', name: 'Tecnofarma', filename: 'tecnofarma.png' },
  { key: 'vtr', name: 'VTR', filename: 'vtr.png' },
  { key: 'wom', name: 'WOM', filename: 'wom.png' },
];

const scrollerRef = ref(null);

onMounted(() => {
  const scroller = scrollerRef.value;

  const addAnimation = () => {
    scroller.setAttribute('data-animated', true);
    const scrollerChild = scroller.firstChild;
    const scrollerContent = Array.from(scrollerChild.children);
    scrollerContent.forEach((item) => {
      const duplicatedItem = item.cloneNode(true);
      duplicatedItem.setAttribute('aria-hidden', true);
      scrollerChild.appendChild(duplicatedItem);
    });
  };

  if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
    addAnimation();
  }
});
</script>

<style scoped>
/* Agrega tus estilos aquí si es necesario */
</style>
