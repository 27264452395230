<template>
  <section class="section-hero w-full px-0 lg:px-0 relative">
    <!-- Imágenes fijas -->
    <span style="background-image: url(/images/bg/bg_hero_left.svg)" class="fixed-image left-0"></span>
    <span style="background-image: url(/images/bg/bg_hero_right.svg)"
      class="fixed-image right-0 transform translate-x-1/2"></span>

    <div class="section-hero-content w-full px-0 lg:px-0 relative z-10">
      <Carousel :autoplay="4000" :navigationEnabled="false" :paginationEnabled="false" :wrapAround="true"
        :pauseAutoplayOnHover="true" class="w-full bg-transparent">
        <!-- Primera slide: Odoo -->
        <Slide>
          <div class="slide-content w-full h-[100vh] flex items-center justify-center relative px-0 lg:px-0">
            <div
              class="hero-text-container flex flex-col items-center justify-center gap-6 lg:gap-8 w-3/4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 lg:w-1/2">
              <h1
                class="hero-title font-semibold text-3xl lg:text-5xl xl:text-6xl text-center text-black drop-shadow-lg">
                {{ odooTitle }}
              </h1>
              <p
                class="hero-description text-base lg:text-lg xl:text-xl text-center text-zinc-700 drop-shadow-md sm:w-96">
                {{ odooSubtitle }}
              </p>

              <div class="flex items-center justify-center gap-4">
                <img src="https://odoocdn.com/openerp_website/static/src/img/assets/png/odoo_logo.png" alt="Odoo Logo"
                  class="w-32 h-auto" />
                <span class="text-5xl font-bold">+</span>
                <img src="/public/images/logo/logo_vanda.png" alt="Vanda Logo" class="w-32 h-auto" />
              </div>
              <button @click="setModalOpen(true)" type="button" id="hero-btn-vanda-odoo"
                class="hero-btn px-4 py-3 text-sm bg-zinc-800 text-zinc-50 text-center rounded-md lg:px-6 lg:py-4 lg:text-md lg:uppercase hover:bg-prime-100 transition-all duration-300 shadow-lg">
                {{ odooButtonText }}
              </button>
            </div>
          </div>
        </Slide>

        <!-- Segunda slide: contenido existente -->
        <Slide>
          <div class="slide-content w-full h-[100vh] flex items-center justify-center relative px-0 lg:px-0">
            <div
              class="hero-text-container flex flex-col items-center justify-center gap-6 lg:gap-8 w-3/4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 lg:w-1/2">
              <h1
                class="hero-title font-semibold text-3xl lg:text-5xl xl:text-6xl text-center text-black drop-shadow-lg">
                Hacemos que la transformación digital sea una realidad.
              </h1>
              <p
                class="hero-description text-base lg:text-lg xl:text-xl text-center text-zinc-700 drop-shadow-md sm:w-96">
                Apoyamos a las pequeñas y grandes empresas en su crecimiento y aumento de productividad laboral.
                Software Factory dedicada al análisis inteligente de datos para compañías.
              </p>
              <button @click="setModalOpen(true)" type="button" id="hero-btn-vanda-main"
                class="hero-btn px-4 py-3 text-sm bg-zinc-800 text-zinc-50 text-center rounded-md lg:px-6 lg:py-4 lg:text-md lg:uppercase hover:bg-prime-100 transition-all duration-300 shadow-lg">
                Reunámonos
              </button>
            </div>
          </div>
        </Slide>

        <!-- Tercera slide: SmartGo -->
        <Slide>
          <div class="slide-content w-full h-[100vh] flex items-center justify-center relative px-0 lg:px-0">
            <div
              class="hero-text-container flex flex-col items-center justify-center gap-6 lg:gap-8 w-3/4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 lg:w-1/2">
              <h1
                class="hero-title font-semibold text-3xl lg:text-5xl xl:text-6xl text-center text-black drop-shadow-lg">
                {{ smartgoTitle }}
              </h1>
              <p
                class="hero-description text-base lg:text-lg xl:text-xl text-center text-zinc-700 drop-shadow-md sm:w-96">
                {{ smartgoSubtitle }}
              </p>

              <img src="/public/images/logo/logo_smartgo.svg" alt="SmartGo Logo" class="w-48 h-auto" />
              <button @click="redirectToSmartGo" type="button" id="hero-btn-smartgo"
                class="hero-btn px-4 py-3 text-sm bg-zinc-800 text-zinc-50 text-center rounded-md lg:px-6 lg:py-4 lg:text-md lg:uppercase hover:bg-prime-100 transition-all duration-300 shadow-lg">
                {{ smartgoButtonText }}
              </button>
            </div>
          </div>
        </Slide>
      </Carousel>
    </div>
  </section>
</template>

<script setup>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import { useModalCalendarContext } from '@/contexts/ModalCalendarContext';

const { setModalOpen } = useModalCalendarContext();

const odooTitle = 'Optimiza y Transforma tu Negocio con Odoo';
const odooSubtitle = 'En Vanda, te ayudamos a transformar tu negocio con Odoo, personalizando la solución para optimizar tus procesos clave y aumentar la productividad. ¡Descubre la herramienta más flexible y escalable para hacer crecer tu empresa!';
const odooButtonText = 'Agenda una consulta gratuita';

const smartgoTitle = 'Conecta y Optimiza tus Procesos con SmartGO';
const smartgoSubtitle = 'Con SmartGO, conecta tus aplicaciones, automatiza procesos y optimiza tu negocio de manera sencilla, todo a través de una plataforma potente y fácil de usar.';
const smartgoButtonText = 'Descubre más';

function redirectToSmartGo() {
  window.location.assign('https://smartgo.cl/landing/');
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.section-hero {
  @apply relative flex justify-center items-center p-5 font-sans w-full;
  overflow: hidden;
  perspective: 1px;
  transform-style: preserve-3d;
}

.section-hero-content {
  @apply flex flex-col items-center justify-center w-full relative z-10;
  animation: fadeIn 1s ease-in-out;
}

.slide-content {
  @apply w-full h-[100vh] flex items-center justify-center relative;
}

.fixed-image {
  @apply w-48 h-48 absolute bottom-0 bg-no-repeat bg-contain sm:w-64 sm:h-64 md:w-80 md:h-80 lg:w-96 lg:h-96 xl:w-[450px] xl:h-[450px];
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
  transform: translateX(50%);
}

.hero-title {
  font-size: 2.5rem;
  color: #000000;
  font-weight: 700;
  line-height: 1.2;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  padding-top: 2rem;
  /* Añadido espacio en la parte superior */
}

.hero-description {
  font-size: 1.125rem;
  color: #4b4b4b;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  width: 100%;
  text-align: center;
  margin-bottom: 1.5rem;
  padding-bottom: 2rem;
  /* Añadido espacio en la parte inferior */
}

.hero-text-container {
  @apply flex flex-col items-center justify-center gap-8 w-full lg:w-1/2;
  animation: slideIn 1s ease-in-out;
  max-width: 100%;
  padding: 0 2rem;
}

.hero-btn {
  font-size: 1.125rem;
  background-color: #333333;
  color: #f9f9f9;
  padding: 16px 32px;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.hero-btn:hover {
  background-color: #f0a500;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 640px) {
  .hero-title {
    font-size: 2rem;
    padding-top: 1.5rem;
    /* Ajuste de espacio superior */
  }

  .hero-description {
    font-size: 1rem;
  }

  .hero-btn {
    font-size: 1rem;
    padding: 12px 24px;
  }

  .slide-content {
    height: 91vh;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
