<template>
  <header id="header" ref="menuRef" class="fixed top-0 left-0 w-full py-4 px-4 z-50 bg-white border-b border-zinc-200">
    <div class="container mx-auto flex items-center justify-between">
      <router-link to="/" class="z-50">
        <img src="/images/logo/logo_vanda.png" alt="Logo Vanda"
          class="block w-20 h-auto lg:w-28 transition-transform duration-300 hover:scale-105" />
      </router-link>

      <!-- Desktop Menu -->
      <nav class="hidden lg:flex items-center gap-6 text-lg">
        <router-link to="/" class="hover:text-prime-100 transition-colors">Inicio</router-link>
        <div class="relative group">
          <button @click="toggleServicesMenu" class="flex items-center gap-2 hover:text-prime-100 transition-colors">
            Servicios
            <ChevronDownIcon
              :class="{ 'rotate-180': servicesMenuOpen, 'transition-transform': true, 'duration-300': true }"
              class="w-5 h-5" />
          </button>
          <div v-if="servicesMenuOpen"
            class="absolute left-0 mt-2 w-96 bg-white border border-zinc-200 rounded-md shadow-lg transition-opacity duration-300 opacity-0 group-hover:opacity-100"
            @mouseleave="closeServicesMenu">
            <router-link to="/services/software-factory"
              class="block px-6 py-4 hover:bg-zinc-100 flex items-start gap-4 transition-colors">
              <img src="/images/servicios/software-factory.png" alt="Software Factory" class="w-12 h-12 inline-block" />
              <div>
                <strong>Software Factory</strong>
                <p class="text-xs text-zinc-500">
                  Desarrollamos software a medida utilizando una amplia variedad de lenguajes y
                  herramientas.
                </p>
              </div>
            </router-link>
            <router-link to="/services/process-automation"
              class="block px-6 py-4 hover:bg-zinc-100 flex items-start gap-4 transition-colors">
              <img src="/images/servicios/process-automation.png" alt="Process Automation"
                class="w-12 h-12 inline-block" />
              <div>
                <strong>Process Automation</strong>
                <p class="text-xs text-zinc-500">
                  Automatizamos procesos para mejorar la eficiencia y reducir errores.
                </p>
              </div>
            </router-link>
            <router-link to="/services/big-data"
              class="block px-6 py-4 hover:bg-zinc-100 flex items-start gap-4 transition-colors">
              <img src="/images/servicios/big-data.png" alt="Big Data" class="w-12 h-12 inline-block" />
              <div>
                <strong>Big Data</strong>
                <p class="text-xs text-zinc-500">
                  Analizamos grandes volúmenes de datos para obtener insights valiosos.
                </p>
              </div>
            </router-link>
            <router-link to="/services/cloud-services"
              class="block px-6 py-4 hover:bg-zinc-100 flex items-start gap-4 transition-colors">
              <img src="/images/servicios/cloud-services.png" alt="Cloud Services" class="w-12 h-12 inline-block" />
              <div>
                <strong>Cloud Services</strong>
                <p class="text-xs text-zinc-500">
                  Ofrecemos soluciones en la nube para mejorar la escalabilidad y flexibilidad.
                </p>
              </div>
            </router-link>
          </div>
        </div>
        <button @click="scrollToSection('#products')" class="hover:text-prime-100 transition-colors">
          Productos
        </button>
        <button @click="scrollToSection('#clients')" class="hover:text-prime-100 transition-colors">
          Clientes
        </button>
        <button @click="scrollToSection('#values')" class="hover:text-prime-100 transition-colors">
          Creamos valor
        </button>
        <button @click="scrollToSection('#blogs')" class="hover:text-prime-100 transition-colors">
          Blogs
        </button>
      </nav>

      <!-- Mobile Menu Button -->
      <button @click="menuOpen = !menuOpen" class="lg:hidden z-50">
        <Bars3Icon class="w-6 h-6 text-zinc-700" />
      </button>

      <!-- Mobile Menu (Full Screen) -->
      <TransitionRoot as="nav" :show="menuOpen" class="fixed top-0 left-0 z-40 w-screen h-screen bg-white lg:hidden"
        enter="transition ease-out duration-300" enter-from="transform opacity-0 -translate-x-full"
        enter-to="transform opacity-100 translate-x-0" leave="transition ease-in duration-200"
        leave-from="transform opacity-100 translate-x-0" leave-to="transform opacity-0 -translate-x-full">
        <div class="relative w-full h-full">
          <!-- Close Button -->
          <button @click="menuOpen = false" class="absolute top-4 right-4 z-50">
            <XMarkIcon class="w-6 h-6 text-zinc-700" />
          </button>

          <!-- Mobile Menu Items -->
          <div class="flex flex-col items-start px-6 pt-20 gap-6">
            <router-link to="/" @click="menuOpen = false" class="block py-2 text-lg text-zinc-700 hover:text-prime-100">
              Inicio
            </router-link>

            <button @click="toggleServicesMenuMobile"
              class="flex items-center py-2 text-lg text-zinc-700 hover:text-prime-100">
              Servicios
              <ChevronDownIcon
                :class="{ 'rotate-180': servicesMenuOpenMobile, 'transition-transform': true, 'duration-300': true }"
                class="w-5 h-5 ml-1" />
            </button>

            <div v-if="servicesMenuOpenMobile" class="w-full">
              <router-link to="/services/software-factory" @click="menuOpen = false"
                class="block pl-4 py-2 text-zinc-600 hover:text-prime-100">
                Software Factory
              </router-link>
              <router-link to="/services/process-automation" @click="menuOpen = false"
                class="block pl-4 py-2 text-zinc-600 hover:text-prime-100">
                Process Automation
              </router-link>
              <router-link to="/services/big-data" @click="menuOpen = false"
                class="block pl-4 py-2 text-zinc-600 hover:text-prime-100">
                Big Data
              </router-link>
              <router-link to="/services/cloud-services" @click="menuOpen = false"
                class="block pl-4 py-2 text-zinc-600 hover:text-prime-100">
                Cloud Services
              </router-link>
            </div>

            <button @click="scrollToSection('#products'); menuOpen = false;"
              class="block py-2 text-lg text-zinc-700 hover:text-prime-100">
              Productos
            </button>
            <button @click="scrollToSection('#clients'); menuOpen = false;"
              class="block py-2 text-lg text-zinc-700 hover:text-prime-100">
              Clientes
            </button>
            <button @click="scrollToSection('#values'); menuOpen = false;"
              class="block py-2 text-lg text-zinc-700 hover:text-prime-100">
              Creamos valor
            </button>
            <button @click="scrollToSection('#blogs'); menuOpen = false;"
              class="block py-2 text-lg text-zinc-700 hover:text-prime-100">
              Blogs
            </button>
          </div>
        </div>

      </TransitionRoot>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue'; // Import nextTick
import { TransitionRoot } from '@headlessui/vue';
import { Bars3Icon, XMarkIcon, ChevronDownIcon } from '@heroicons/vue/24/outline';
import { useRouter } from 'vue-router';

const menuRef = ref(null);
const menuOpen = ref(false);
const servicesMenuOpen = ref(false); // For desktop
const servicesMenuOpenMobile = ref(false); // For mobile

const toggleServicesMenu = () => {
  servicesMenuOpen.value = !servicesMenuOpen.value;
};
const toggleServicesMenuMobile = () => {
  servicesMenuOpenMobile.value = !servicesMenuOpenMobile.value;
  // Close the desktop services menu if open
  if (servicesMenuOpen.value) {
    servicesMenuOpen.value = false;
  }
}

const closeServicesMenu = () => {
  servicesMenuOpen.value = false;
};


const router = useRouter();


const scrollToSection = (selector) => {
  const section = document.querySelector(selector);

  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
    menuOpen.value = false; // Close the mobile menu
  } else {
    // If the section is not on the current page, navigate to the home page
    router.push('/').then(() => {
      // Wait for the navigation and DOM update to complete, *then* scroll
      nextTick(() => { // Use nextTick instead of setTimeout
        const sectionAfterNavigation = document.querySelector(selector);
        if (sectionAfterNavigation) {
          sectionAfterNavigation.scrollIntoView({ behavior: 'smooth' });
        }
      });
    });
  }
};

</script>
