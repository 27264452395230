<template>
  <footer id="footer" class="py-10">
    <div class="container mx-auto px-3">
      <div class="flex flex-col gap-5 lg:flex-row lg:justify-between">
        <div class="grid grid-cols-2 gap-4 lg:order-2 lg:flex-initial lg:w-1/2">
          <div>
            <h2 class="text-xs text-zinc-500 font-semibold mb-5 lg:text-base">Secciones</h2>
            <div class="flex flex-col gap-1 text-xs text-zinc-500 lg:text-sm">
              <a href="#home" class="w-full hover:text-zinc-600 hover:underline">Inicio</a>
              <a href="#services" class="w-full hover:text-zinc-600 hover:underline">Servicios</a>
              <a href="#products" class="w-full hover:text-zinc-600 hover:underline">Productos</a>
              <a href="#clients" class="w-full hover:text-zinc-600 hover:underline">Clientes</a>
              <a href="#values" class="w-full hover:text-zinc-600 hover:underline">Creamos valor</a>
            </div>
          </div>
          <div>
            <h2 class="text-xs text-zinc-500 font-semibold mb-5 lg:text-base">
              Nuestra empresa
            </h2>
            <div class="flex flex-col gap-1 text-xs text-zinc-500 lg:text-sm">
              <p>Escríbenos</p>
              <p>Llámanos</p>
              <p>Ubicación</p>
            </div>
          </div>
        </div>

        <router-link to="/" class="mb-3 lg:order-1 lg:flex-initial lg:w-32">
          <img src="/images/logo/logo_vanda.png" alt="Logo Vanda" class="block w-20 h-auto lg:w-28" />
        </router-link>

        <div class="text-xs text-zinc-400 lg:order-last">
          <p class="mb-3">© {{ new Date().getFullYear() }} Vanda Technologies & Consulting</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
/* Agrega tus estilos aquí */
</style>
