<template>
  <TransitionRoot :show="modalOpen" as="template">
    <Dialog as="div" class="relative z-50" @click.self="setModalOpen(false)">
      <TransitionChild as="template" enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100"
        leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
        <div class="fixed inset-0 bg-slate-100 bg-opacity-75 transition-opacity" @click="setModalOpen(false)" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0" @click.stop>
          <TransitionChild as="template" enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl h-screen"
              @click.stop>
              <div class="absolute right-0 top-0 pr-4 pt-4 sm:block">
                <button type="button" class="rounded-md bg-white text-zinc-400 hover:text-zinc-500 focus:outline-none"
                  @click="setModalOpen(false)">
                  <span class="sr-only">Cerrar</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <div class="overflow-y-auto">
                <iframe
                  src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1H6ZVSpZSFUWiH-l3Pna13KChfeJrlVed38gjFXLpVBhYq5yQgcmg06paF7wKYXI8-hwcHNuUJ?gv=true"
                  style="border: 0" width="100%" height="700" frameborder="0"></iframe>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from 'vue';
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { useModalCalendarContext } from '@/contexts/ModalCalendarContext';

const { modalOpen, setModalOpen } = useModalCalendarContext();
</script>
