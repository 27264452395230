<template>
  <section id="clients" class="container mx-auto py-10 px-3 scroll-mt-20 md:py-20">
    <header class="mb-5 md:mb-10 xl:mb-20">
      <h1 class="font-semibold text-2xl md:text-4xl xl:text-center">
        Compañías líderes confían en nuestra experiencia.
      </h1>
    </header>
    <div class="my-10">
      <ClientsCarousel />
      <Presencia ref="presenciaSection" />
      <Testimonials />
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import ClientsCarousel from '../ui/ClientsCarousel.vue';
import Presencia from '../ui/ClientPresence.vue';
import Testimonials from '../ui/ClientTestimonials.vue';

const presenciaSection = ref(null);
</script>
