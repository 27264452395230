<template>
  <section class="w-full min-h-fit border-b border-b-zinc-200 mb-10">
    <div
      class="container mx-auto mt-20 py-10 px-3 flex flex-col items-center justify-start gap-4 lg:flex-row lg:w-3/5 lg:py-20 lg:gap-10 lg:items-start"
    >
      <img
        src="/images/servicios/big-data.png"
        alt="Big Data"
        class="w-16 aspect-square lg:w-24"
      />
      <div class="text-zinc-800 text-center lg:text-left">
        <router-link
          to="/"
          class="text-sm leading-6 flex items-center hover:text-zinc-500"
          :style="{ color: '#73D66A' }"
        >
          <ChevronLeftIcon class="w-4 h-4" /> Volver
        </router-link>
        <FwbHeading tag="h1" class="text-2xl mb-5 font-medium uppercase">
          Consultoría de Datos (Data Consulting)
        </FwbHeading>
        <FwbP class="text-sm text-zinc-500">
          Potenciamos tu éxito a través de roadmaps estratégicos que te brindan una visión
          completa y detallada de tu negocio. ¿Estás listo para construir plataformas de
          analítica con información integrada, segura y de calidad?
        </FwbP>
      </div>
    </div>
  </section>

  <section class="container mx-auto py-10 px-3 lg:py-20 lg:w-3/5">
    <header class="mb-5 xl:mb-10 xl:w-3/4 xl:mx-auto xl:text-center">
      <FwbHeading tag="h1" class="text-2xl text-center font-semibold mb-4">
        ¿Estás listo para construir plataformas de analítica con información integrada, segura
        y de calidad?
      </FwbHeading>
      <FwbP class="text-sm text-slate-400 mx-auto text-center">
        Potenciamos tu éxito a través de roadmaps estratégicos que te brindan una visión
        completa y detallada de tu negocio.
      </FwbP>
    </header>

    <FwbP class="text-sm mb-5">
      En VANDA, nos especializamos en acompañarte en cada paso de este emocionante viaje.
      Nuestro equipo de expertos trabaja incansablemente para crear roadmaps ordenados y
      secuenciados que te guíen hacia el logro de tus objetivos.
    </FwbP>
    <FwbP class="text-sm mb-5">
      El Business Intelligence es nuestra herramienta clave para convertir tus datos en una
      experiencia visual y amigable. Creamos formas interactivas que te permiten explorar y
      comprender fácilmente la información más relevante. Descubre insights, patrones y
      tendencias que impactan en tu negocio.
    </FwbP>
    <FwbP class="text-sm mb-5">
      No pierdas más tiempo tratando de encontrar respuestas en medio de un mar de datos
      confusos. Con nuestro enfoque comercial y orientado al éxito, te ayudaremos a desbloquear
      el verdadero potencial de tu negocio.
    </FwbP>
    <FwbP class="my-5 text-center text-base font-semibold">
      ¡Contacta con nosotros hoy mismo y déjanos ser tu socio estratégico en la construcción de
      roadmaps que impulsarán tu crecimiento empresarial!
    </FwbP>
  </section>

  <section class="container mx-auto py-10 px-3 lg:py-20">
    <header class="mb-5 xl:mb-10 xl:w-3/4 xl:mx-auto xl:text-center">
      <FwbHeading tag="h1" class="text-xl text-center font-xl">
        Los datos son el activo principal de una compañía, por eso se deben organizar,
        manipular y canalizar de manera que su entendimiento sea sencillo y práctico porque:
      </FwbHeading>
    </header>

    <div
      class="w-3/4 mx-auto flex flex-col items-start justify-start gap-5 text-center sm:grid sm:grid-cols-3 sm:gap-5 sm:items-stretch"
    >
      <div class="bg-c3-200 p-2 rounded-md text-sm lg:py-10 lg:text-lg">
        El <strong>90%</strong> de los datos fue creado recientemente y requiere un tratamiento
        oportuno
      </div>
      <div class="bg-c3-200 p-2 rounded-md text-sm lg:py-10 lg:text-lg">
        Las empresas analizan solo el <strong>12%</strong> de los datos que tienen en sus
        sistemas internos
      </div>
      <div class="bg-c3-200 p-2 rounded-md text-sm lg:py-10 lg:text-lg">
        Los datos falsos o no verídicos pueden costar
        <strong>US 3.1 billones anualmente</strong>
      </div>
    </div>
    <a
      href="https://www.dataiq.global/home"
      target="_blank"
      class="block my-2 text-xs text-center text-zinc-400"
    >
      *Fuente: DataIQ
    </a>
  </section>

  <section class="container mx-auto py-10 px-3 lg:py-20">
    <header class="mb-5 xl:mb-10 xl:w-3/4 xl:mx-auto xl:text-center">
      <FwbHeading tag="h1" class="text-xl text-center font-xl">
        Beneficios de Data Analytics
      </FwbHeading>
    </header>
    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 xl:gap-8">
      <div
        ref="detailBox1"
        :class="{
          'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
          'opacity-100 translate-y-0': onScreen1,
          'opacity-0 translate-y-10': !onScreen1,
          'lg:flex-row': true,
        }"
      >
        <CheckCircleIcon class="w-6 h-6 text-c3-200 shrink-0 lg:w-8 lg:h-8" />
        <div class="text-center lg:text-left">
          <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl"
            >Toma decisiones informadas</FwbHeading
          >
          <FwbP class="text-xs lg:text-sm">
            Data analytics te brinda datos precisos para tomar decisiones fundamentadas,
            ajustando estrategias y optimizando conversiones.
          </FwbP>
        </div>
      </div>

      <div
        ref="detailBox2"
        :class="{
          'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
          'opacity-100 translate-y-0': onScreen2,
          'opacity-0 translate-y-10': !onScreen2,
          'lg:flex-row': true,
        }"
      >
        <CheckCircleIcon class="w-6 h-6 text-c3-200 shrink-0 lg:w-8 lg:h-8" />
        <div class="text-center lg:text-left">
          <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl"
            >Mejora la personalización</FwbHeading
          >
          <FwbP class="text-xs lg:text-sm">
            Comprende mejor a tu audiencia y ofrece experiencias personalizadas, adaptando
            contenido y ofertas a sus necesidades individuales.
          </FwbP>
        </div>
      </div>

      <div
        ref="detailBox3"
        :class="{
          'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
          'opacity-100 translate-y-0': onScreen3,
          'opacity-0 translate-y-10': !onScreen3,
          'lg:flex-row': true,
        }"
      >
        <CheckCircleIcon class="w-6 h-6 text-c3-200 shrink-0 lg:w-8 lg:h-8" />
        <div class="text-center lg:text-left">
          <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl"
            >Detecta oportunidades de crecimiento</FwbHeading
          >
          <FwbP class="text-xs lg:text-sm">
            Identifica nichos de mercado, patrones de comportamiento y tendencias emergentes
            para aprovechar oportunidades de crecimiento.
          </FwbP>
        </div>
      </div>

      <div
        ref="detailBox4"
        :class="{
          'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
          'opacity-100 translate-y-0': onScreen4,
          'opacity-0 translate-y-10': !onScreen4,
          'lg:flex-row': true,
        }"
      >
        <CheckCircleIcon class="w-6 h-6 text-c3-200 shrink-0 lg:w-8 lg:h-8" />
        <div class="text-center lg:text-left">
          <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl">
            Optimiza la experiencia del usuario
          </FwbHeading>
          <FwbP class="text-xs lg:text-sm">
            Analiza datos de interacción para identificar puntos de fricción y mejorar la
            usabilidad, aumentando la retención y satisfacción del usuario.
          </FwbP>
        </div>
      </div>

      <div
        ref="detailBox5"
        :class="{
          'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
          'opacity-100 translate-y-0': onScreen5,
          'opacity-0 translate-y-10': !onScreen5,
          'lg:flex-row': true,
        }"
      >
        <CheckCircleIcon class="w-6 h-6 text-c3-200 shrink-0 lg:w-8 lg:h-8" />
        <div class="text-center lg:text-left">
          <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl">
            Mide el rendimiento de tus campañas
          </FwbHeading>
          <FwbP class="text-xs lg:text-sm">
            Evalúa el rendimiento de anuncios, tasas de clics, conversiones y retorno de
            inversión para ajustar estrategias de marketing y optimizar resultados.
          </FwbP>
        </div>
      </div>
    </div>
  </section>

  <section class="container mx-auto py-10 px-3 lg:py-20 lg:w-3/4">
    <FwbP class="text-sm text-center lg:text-base">
      En resumen, la automatización de procesos de negocio es una herramienta poderosa para
      mejorar la eficiencia, reducir costos y aumentar la precisión en una empresa, lo que a su
      vez conduce a una mayor competitividad y satisfacción del cliente.
    </FwbP>
  </section>

  <SectionCTA />
</template>

<script setup>
import { FwbHeading, FwbP } from 'flowbite-vue';
import { ref, onMounted, onUnmounted } from 'vue';
import { CheckCircleIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline';
import SectionCTA from '@/components/sections/SectionCTA.vue';

const detailBox1 = ref(null);
const detailBox2 = ref(null);
const detailBox3 = ref(null);
const detailBox4 = ref(null);
const detailBox5 = ref(null);
const onScreen1 = ref(false);
const onScreen2 = ref(false);
const onScreen3 = ref(false);
const onScreen4 = ref(false);
const onScreen5 = ref(false);

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.target === detailBox1.value) {
        onScreen1.value = entry.isIntersecting;
      } else if (entry.target === detailBox2.value) {
        onScreen2.value = entry.isIntersecting;
      } else if (entry.target === detailBox3.value) {
        onScreen3.value = entry.isIntersecting;
      } else if (entry.target === detailBox4.value) {
        onScreen4.value = entry.isIntersecting;
      } else if (entry.target === detailBox5.value) {
        onScreen5.value = entry.isIntersecting;
      }
    });
  },
  { threshold: 0.5 }
);

onMounted(() => {
  if (detailBox1.value) observer.observe(detailBox1.value);
  if (detailBox2.value) observer.observe(detailBox2.value);
  if (detailBox3.value) observer.observe(detailBox3.value);
  if (detailBox4.value) observer.observe(detailBox4.value);
  if (detailBox5.value) observer.observe(detailBox5.value);
});

onUnmounted(() => {
  if (detailBox1.value) observer.unobserve(detailBox1.value);
  if (detailBox2.value) observer.unobserve(detailBox2.value);
  if (detailBox3.value) observer.unobserve(detailBox3.value);
  if (detailBox4.value) observer.unobserve(detailBox4.value);
  if (detailBox5.value) observer.unobserve(detailBox5.value);
});
</script>
