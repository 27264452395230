<template>
  <section class="w-full min-h-fit border-b border-b-zinc-200 mb-10">
    <div
      class="container mx-auto mt-20 py-10 px-3 flex flex-col items-center justify-start gap-4 lg:flex-row lg:w-3/5 lg:py-20 lg:gap-10 lg:items-start"
    >
      <img
        src="/images/servicios/cloud-services.png"
        alt="Cloud Services"
        class="w-16 aspect-square lg:w-24"
      />
      <div class="text-zinc-800 text-center lg:text-left">
        <router-link
          to="/"
          class="text-sm leading-6 text-c4-200 flex items-center hover:text-zinc-500"
        >
          <ChevronLeftIcon class="w-4 h-4" /> Volver
        </router-link>
        <h1 class="text-2xl mb-5 font-medium uppercase">Cloud Services</h1>
        <p class="text-sm text-zinc-500">
          En nuestro equipo, contamos con expertos en arquitectura cloud altamente capacitados
          que aplican su amplia experiencia para brindarte soluciones de vanguardia.
          Aprovechamos las Funciones como Servicios (FaaS) para gestionar procesos intensivos
          de manera eficiente y seguimos una arquitectura hexagonal que garantiza un diseño
          sólido y modular.
        </p>
      </div>
    </div>
  </section>

  <section class="container mx-auto py-10 px-3 lg:py-20">
    <header class="mb-5 xl:mb-10 xl:w-3/4 xl:mx-auto xl:text-center">
      <FwbHeading tag="h1" class="text-xl text-center font-xl">
        Beneficios de la Automatización de Procesos de negocio
      </FwbHeading>
    </header>
    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 xl:gap-8">
      <div
        ref="detailBox1"
        :class="{
          'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
          'opacity-100 translate-y-0': onScreen1,
          'opacity-0 translate-y-10': !onScreen1,
          'lg:flex-row': true,
        }"
      >
        <CheckCircleIcon class="w-6 h-6 text-c4-200 shrink-0 lg:w-8 lg:h-8" />
        <div class="text-center lg:text-left">
          <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl"
            >Respaldo a nivel mundial</FwbHeading
          >
          <FwbP class="text-xs lg:text-sm">
            Nos enorgullece colaborar con socios estratégicos de renombre, quienes nos
            respaldan en la provisión de las soluciones en la nube más avanzadas y eficientes
            del mercado.
          </FwbP>
        </div>
      </div>

      <div
        ref="detailBox2"
        :class="{
          'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
          'opacity-100 translate-y-0': onScreen2,
          'opacity-0 translate-y-10': !onScreen2,
          'lg:flex-row': true,
        }"
      >
        <CheckCircleIcon class="w-6 h-6 text-c4-200 shrink-0 lg:w-8 lg:h-8" />
        <div class="text-center lg:text-left">
          <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl"
            >Enfoque personalizado</FwbHeading
          >
          <FwbP class="text-xs lg:text-sm">
            Trabajaremos de la mano contigo para entender a fondo las necesidades de tu empresa
            y personalizar cada solución de acuerdo a tus metas y objetivos específicos.
          </FwbP>
        </div>
      </div>

      <div
        ref="detailBox3"
        :class="{
          'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
          'opacity-100 translate-y-0': onScreen3,
          'opacity-0 translate-y-10': !onScreen3,
          'lg:flex-row': true,
        }"
      >
        <CheckCircleIcon class="w-6 h-6 text-c4-200 shrink-0 lg:w-8 lg:h-8" />
        <div class="text-center lg:text-left">
          <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl"
            >Escalabilidad</FwbHeading
          >
          <FwbP class="text-xs lg:text-sm">
            Optimizaremos el rendimiento y la escalabilidad de tus sistemas, permitiendo
            adaptarte con agilidad a los cambios y afrontar picos de demanda sin problemas.
          </FwbP>
        </div>
      </div>

      <div
        ref="detailBox4"
        :class="{
          'bg-zinc-50 px-4 py-5 rounded-md flex flex-col items-center gap-5 transition-all ease-in duration-300': true,
          'opacity-100 translate-y-0': onScreen4,
          'opacity-0 translate-y-10': !onScreen4,
          'lg:flex-row': true,
        }"
      >
        <CheckCircleIcon class="w-6 h-6 text-c4-200 shrink-0 lg:w-8 lg:h-8" />
        <div class="text-center lg:text-left">
          <FwbHeading tag="h3" class="text-md font-semibold mb-2 text-c1 lg:text-xl">
            Reducción de costos operativos
          </FwbHeading>
          <FwbP class="text-xs lg:text-sm">
            Aprovechamos al máximo los recursos de la nube y te brindamos una infraestructura
            flexible y escalable.
          </FwbP>
        </div>
      </div>
    </div>
  </section>

  <SectionCTA />
</template>

<script setup>
import { FwbHeading, FwbP } from 'flowbite-vue';
import { defineComponent } from 'vue';
import { ref, onMounted, onUnmounted } from 'vue';
import { ChevronLeftIcon, CheckCircleIcon } from '@heroicons/vue/24/outline';
import { useIntersectionObserver } from '@/composables/useIntersectionObserver';
import SectionCTA from '@/components/sections/SectionCTA.vue';

const detailBox1 = ref(null);
const detailBox2 = ref(null);
const detailBox3 = ref(null);
const detailBox4 = ref(null);
const onScreen1 = ref(false);
const onScreen2 = ref(false);
const onScreen3 = ref(false);
const onScreen4 = ref(false);
const onScreen5 = ref(false);

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.target === detailBox1.value) {
        onScreen1.value = entry.isIntersecting;
      } else if (entry.target === detailBox2.value) {
        onScreen2.value = entry.isIntersecting;
      } else if (entry.target === detailBox3.value) {
        onScreen3.value = entry.isIntersecting;
      } else if (entry.target === detailBox4.value) {
        onScreen4.value = entry.isIntersecting;
      }
    });
  },
  { threshold: 0.5 }
);

onMounted(() => {
  if (detailBox1.value) observer.observe(detailBox1.value);
  if (detailBox2.value) observer.observe(detailBox2.value);
  if (detailBox3.value) observer.observe(detailBox3.value);
  if (detailBox4.value) observer.observe(detailBox4.value);
});

onUnmounted(() => {
  if (detailBox1.value) observer.unobserve(detailBox1.value);
  if (detailBox2.value) observer.unobserve(detailBox2.value);
  if (detailBox3.value) observer.unobserve(detailBox3.value);
  if (detailBox4.value) observer.unobserve(detailBox4.value);
});
</script>
