import { ref, provide, inject } from 'vue';

const selectedDate = ref(null);

export function provideModalCalendarSelectionContext() {
  const setSelectedDate = (date) => {
    selectedDate.value = date;
  };

  provide('modalCalendarSelectionContext', {
    selectedDate,
    setSelectedDate,
  });
}

export function useModalCalendarSelectionContext() {
  const context = inject('modalCalendarSelectionContext');
  if (!context) {
    throw new Error(
      'useModalCalendarSelectionContext must be used within a ModalCalendarSelectionProvider'
    );
  }
  return context;
}
