<template>
  <div>
    <h2 class="mt-10 mb-6 pb-3 border-b border-zinc-200 text-center text-sm text-zinc-500 md:text-base">
      Testimonios
    </h2>

    <div class="relative">
      <Swiper ref="sliderRef" :space-between="10" :loop="true"
        :autoplay="{ delay: 3500, disableOnInteraction: false, pauseOnMouseEnter: true }" :breakpoints="breakpoints"
        :modules="[Autoplay]" class="mb-5" @reach-beginning="onReachBeginning" @reach-end="onReachEnd"
        @swiper="onSwiper" @slide-change="onSlideChange">
        <SwiperSlide v-for="testimonial in TESTIMONIALS" :key="`testimonial-${testimonial.key}`">
          <div
            class="testimonial-slide min-h-[350px] bg-zinc-100 rounded-md p-3 flex flex-col items-center justify-between gap-5 md:p-5 xl:min-h-[476px] xl:py-10 xl:px-5">
            <div class="text-center">
              <p class="text-xs mb-4 sm:text-sm">{{ testimonial.text }}</p>
              <h1 class="text-xxs text-zinc-500 semibold sm:text-xs xl:text-sm">
                {{ testimonial.name }}
              </h1>
              <h2 class="text-xxs text-zinc-500 sm:text-xs xl:text-sm">
                {{ testimonial.position }}
              </h2>
            </div>
            <img :src="`images/clients/${testimonial.logoPath}`"
              :alt="`Testimonial by ${testimonial.name} ${testimonial.position}`" class="w-auto h-6 md:h-8 xl:h-10" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>

    <button type="button"
      class="block w-40 mx-auto py-2 bg-prime-100 text-gray-50 rounded-md hover:bg-prime-200 transition-all md:w-52"
      @click="setModalOpen(true)">
      Agenda una reunión
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';

import { useModalCalendarContext } from '@/contexts/ModalCalendarContext';

const { setModalOpen } = useModalCalendarContext();

const TESTIMONIALS = [
  {
    key: 'entel',
    text: 'Vanda desarrolló una plataforma de Big Data & IoT, para Aseguramiento Técnico de Ingresos, la cual procesa 5.000 millones de registros diarios en modalidad Near-Real-Time, desde la Red Móvil y Fija de Entel a nivel nacional, utilizando tecnología Open Source.',
    name: 'Salomé Fernández',
    position: 'Jefe Área Aseguramiento Técnico de Ingresos | Entel',
    logoPath: 'entel.png',
  },
  {
    key: 'electrolux',
    text: 'Vanda desarrolla una plataforma mobile (iOS y Android), para la gestión de los promotores en los puntos de ventas, entregando sus coberturas, metas y estrategias comerciales, utilizando Machine Learning. Actualmente la plataforma es utilizada por 400 usuarios activos en todo Chile.',
    name: 'Tamara Sandoval',
    position: 'Point of Sales Intelligence Manager | Electrolux',
    logoPath: 'electrolux.png',
  },
  {
    key: 'skberge',
    text: 'VANDA desarrolló la plataforma de BI corporativa, extrayendo data principalmente desde SAP y utilizando las herramientas de Analytics de SAP. La cobertura del proyecto es regional impactando a Chile, Perú y Colombia.',
    name: 'Gustavo Carreño',
    position: 'Subgerente de TI | SKBerge',
    logoPath: 'skberge.png',
  },
  {
    key: 'wom',
    text: 'Vanda realizó la primera implementación de IBM Watson Studio a nivel nacional. Adicionalmente, Vanda desarrolló procesos de preparación de datos, ingesta, pre-procesamiento de datos y deploy de modelo (R) para predicción de port out, con modalidad híbrida (cloud/on-premise) y orquestamiento vía Datastage.',
    name: 'Rubén Shlaman',
    position: 'Jefe de Analytics & Big Data | WOM',
    logoPath: 'wom.png',
  },
  {
    key: 'aramark',
    text: 'VANDA desarrolló el Sistema de Planificación y Gestor de Costos para licitaciones. Esto nos permite controlar el ciclo completo de las licitaciones manteniendo en todo momento la eficiencia del proceso en su ciclo de desarrollo. Logramos como área subirnos a la Transformación Digital haciendo que este proceso fundamental para nuestra organización pasara de la manipulación manual de datos propenso a errores, a un proceso controlado y gestionado, apuntando siempre a la mejora continua.',
    name: 'Pilar Abarca',
    position: 'Gerente Gestión Operacional | Aramark',
    logoPath: 'aramark.png',
  },
  {
    key: 'astara',
    text: 'VANDA nos apoya en el desarrollo y soporte continuo del Data Warehouse corporativo y BI de distintas áreas, utilizando el bundle de SAP Business Objects y herramientas de Data Discovery, abarcando las áreas de Clientes, Finanzas, Servicios, Ventas, Operaciones y otras.',
    name: 'Gustavo Carreño',
    position: 'Subgerente de Sistemas | Astara',
    logoPath: 'astara.png',
  },
  {
    key: 'enjoy',
    text: 'VANDA realiza la optimización del motor de reserva de hoteles de ENJOY, el cual se realiza por medio del sistema worldclass OPERA. Este motor de reservas de hospedaje tenía tiempos extensos en el cálculo del algoritmo, principalmente por las consultas de borde que demoraban más del tiempo estándar aceptado para la plataforma on-line. VANDA realiza ingeniería inversa del proceso y logra optimizar el tiempo en un 90%.',
    name: 'José Alvarado',
    position: 'Jefe de Infraestructura | Enjoy',
    logoPath: 'enjoy.png',
  },
];

const sliderRef = ref(null);

// Breakpoints for responsive behavior
const breakpoints = {
  320: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
  480: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  640: {
    slidesPerView: 2,
    spaceBetween: 30,
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 40,
  },
  1280: {
    slidesPerView: 4,
    spaceBetween: 50,
  },
};

const onSwiper = (swiper) => {
  sliderRef.value = swiper;
};

const onSlideChange = (swiper) => {
  console.log('Slide changed:', swiper.activeIndex);
};

const onReachBeginning = () => {
  console.log('Swiper reached the beginning');
};

const onReachEnd = () => {
  console.log('Swiper reached the end');
};

onMounted(() => {
  if (sliderRef.value) {
    console.log('Swiper instance:', sliderRef.value);
  } else {
    console.error('Swiper ref is null!');
  }
});
</script>

<style scoped>
.testimonial-logo {
  width: auto;
  height: 3rem;
}

@media (min-width: 640px) {
  .testimonial-logo {
    height: 4rem;
  }
}

@media (min-width: 1024px) {
  .testimonial-logo {
    height: 5rem;
  }
}

@media (min-width: 1280px) {
  .testimonial-logo {
    height: 6rem;
  }
}

.testimonial-slide {
  min-height: 350px;
}

@media (max-width: 640px) {
  .testimonial-slide {
    min-height: 300px;
    padding: 1rem;
  }
}

@media (min-width: 641px) and (max-width: 1023px) {
  .testimonial-slide {
    min-height: 350px;
    padding: 1.5rem;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .testimonial-slide {
    min-height: 400px;
    padding: 2rem;
  }
}

@media (min-width: 1280px) {
  .testimonial-slide {
    min-height: 476px;
    padding: 2.5rem;
  }
}
</style>
