<template>
  <div id="top" class="min-h-screen">
    <AppHeader />
    <main>
      <SectionHero ref="heroSection" />
      <SectionOdoo />
      <SectionServices />
      <SectionProducts ref="productsSection" />
      <SectionClients />
      <SectionValue ref="valueSection" />
      <SectionBlog ref="blogSection" />
      <SectionCTA />
    </main>
    <FloatingButton />
    <ModalCalendar />
  </div>
</template>

<script setup>
import { provideModalCalendarContext } from '@/contexts/ModalCalendarContext';
import { ref } from 'vue';

import AppHeader from '@/components/header/AppHeader.vue';
import SectionHero from '@/components/sections/SectionHero.vue';
import SectionServices from '@/components/sections/SectionServices.vue';
import SectionProducts from '@/components/sections/SectionProducts.vue';
import SectionClients from '@/components/sections/SectionClients.vue';
import SectionValue from '@/components/sections/SectionValue.vue';
import SectionBlog from '@/components/sections/SectionBlog.vue';
import SectionCTA from '@/components/sections/SectionCTA.vue';
import AppFooter from '@/components/footer/footer.vue';
import FloatingButton from '@/components/ui/FloatingButton.vue';
import ModalCalendar from '@/components/ui/ModalCalendar.vue';
import SectionOdoo from '@/components/sections/SectionOdoo.vue';

provideModalCalendarContext();

const heroSection = ref(null);
const productsSection = ref(null);
const valueSection = ref(null);
const blogSection = ref(null);
</script>
